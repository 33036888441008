import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from 'gatsby';

import SEO from "../../components/seo";
import ProjectLayout from "../../components/layouts/projectLayout";
import Banner from "../../components/project/banner";
import ProjectSection from "../../components/project/projectSection";
import ProjectFooter from "../../components/project/projectFooter";
import ScreenshotsContainer from "../../components/project/screenshotsContainer";
import {ProjectSectionTitle, ProjectSectionsubtitle, ProjectSectionDescription, ProjectSectionList, ProjectSectionListItem} from "../../components/project/projectTypography";
import {UIAllInOne, UIExplore, UIActivities, BrandingLogos, BrandingApplication, BrandingWebsite} from "../../components/img/koopioImages";


import Colors from "../../constants/colors";
import Fonts from "../../constants/fonts";
import Breakpoints from "../../constants/breakpoints";

// Images
import BannerImage from "../../images/project_banners/Project_banner-Koopio.png";
import ScatteredServicesImage from "../../images/projects/Koopio/Scattered_services.jpg";

import GoalsAllInOneImage from "../../images/projects/Koopio/Goals-All_in_One.png";
import GoalsExploreImage from "../../images/projects/Koopio/Goals-Explore.png";
import GoalsShareImage from "../../images/projects/Koopio/Goals-Share.png";

import DarkGrid from "../../images/projects/Koopio/dark_grid.png";
import IdeationPersona from "../../images/projects/Koopio/Ideation-persona.png";
import IdeationWireframe from "../../images/projects/Koopio/Ideation-wireframe.png";
import IdeationPrototype from "../../images/projects/Koopio/Ideation-prototype.jpg";
import IdeationLofimockup from "../../images/projects/Koopio/Ideation-lofi_mockup.png";
import IdeationTaskflow from "../../images/projects/Koopio/Ideation-task_flow.png";

const getProject = graphql`
  query {
    project:allContentfulProject(filter: {title:{eq: "Koopio"}}) {
      edges {
        node {
          title
          description {
            description
          }
          slug
          order
          year
          role
          contentful_id
        }
      }
    }
  }
`;


const OpportunitiesImage = styled.div`
  background-image: url(${ScatteredServicesImage});
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 260px;

  @media only screen and (max-width: ${Breakpoints.Width.laptop_large}) {
    height: 240px;
  }

  @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
    height: 220px;
  }

  @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
    height: 180px;
  }
`;


const GoalList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, minmax(10rem, 27rem));
  grid-gap: 2.5rem;
  justify-content: center;

  list-style: none;
  margin: 0;


  @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
    grid-gap: 1.5rem;
  }

  @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
  
  li {
    display: block;
    background-color: ${Colors.Basic.white};
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
    padding: 45.5% 20px 20px 20px;
    position: relative;

    @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
      padding-top: 48.5%;
    }

    @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
      padding-top: 44.5%;
    }

    @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
      padding-top: 47%;
    }

    h4 {
      font-family: ${Fonts.Font_family.sans_serif_condensed};
      font-size: 1.375rem;
      line-height: 24px;
      margin-bottom: 12px;
    }

    p {
      font-size: 0.9375rem;
    }

    &.goals-image {
      background-position: 0 0;
      background-size: 100.5% auto;
      background-repeat: no-repeat;
    }

    &.goals-image-allinone {
      background-image: url(${GoalsAllInOneImage});
    }

    &.goals-image-explore {
      background-image: url(${GoalsExploreImage});
    }

    &.goals-image-share {
      background-image: url(${GoalsShareImage});
    }
  }
`;

const IdeationContainer = styled.div`
  display: grid;
  grid-template-rows: 240px 240px 390px;
  grid-template-columns: 45% 55%;
  grid-gap: 8px;

  @media only screen and (max-width: ${Breakpoints.Width.laptop_medium}) {
    grid-template-rows: 220px 220px 360px;
  }

  @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
    grid-template-rows: 220px 220px 330px;
  }

  @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
    grid-template-rows: repeat(5, 250px);
    grid-template-columns: 1fr;
  }

  @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
    grid-template-rows: repeat(5, 360px);
    grid-template-columns: 1fr;
  }

  div {
    border-radius: 2px;
    display: flex;

    .info {
      display: flex;
      flex-direction: column;
      padding: 32px 24px 24px 24px;

      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        padding: 24px;
      }

      h4 {
        font-family: ${Fonts.Font_family.title_condensed};
        font-size: 1.5rem;
        font-weight: 100;
        color: ${Colors.Text.default};
        letter-spacing: 0.5px;
        line-height: 28px;
        position: relative;
        margin-bottom: 24px;

        &::after {
          content: "";
          display: block;
          width: 12px;
          height: 1px;
          position: absolute;
          bottom: -8px;
        }
      }
      p {
        font-size: 0.875rem;
        margin-bottom: 0;
      }
    }

    .image {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 50% 50%;
    }
  }

  .persona {
    grid-row: 1 / 3;
    grid-column: 1 / 2;
    background-color: #F8FAF7;
    padding-bottom: 20px;
    flex-direction: column;

    @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      flex-direction: row;
      padding-bottom: 0;
    }

    @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
      flex-direction: column;
    }

    .info {

      @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
        width: 45%; 
      }

      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        width: 100%; 
      }

      h4 {
        &::after {
          background-color: ${Colors.Underline.green};
        }
      }
    }

    .image {
      flex-grow: 1;
      background-image: url(${IdeationPersona});
      background-size: 100% auto;
      background-position: 50% 0;
      margin: 0 24px;

      @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
        margin: 0; 
        background-position: 50% 50%;
      }

      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        background-position: 50% 0;
      }

      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        margin: 0 24px 8px;
      }
    }

  }

  .wireframe {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    background-color: #F8FAFA;
    flex-direction: row;

    @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }

    @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
      flex-direction: column;
    }

    .info {
      width: 31.4285%;

      @media only screen and (max-width: ${Breakpoints.Width.laptop_medium}) {
        width: 35%;
      }

      @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
        width: 40%;
      }

      @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
        width: 45%; 
      }

      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        width: 100%; 
      }

      h4 {
        &::after {
          background-color: ${Colors.Underline.blue};
        }
      }
    }

    .image {
      flex-grow: 1;
      background-image: url(${IdeationWireframe});
      background-size: cover;
      background-position: 0 50%;
      margin: 0;
      border-left: 1px solid #f2f2f2;
    }
  }
  .prototype {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    background-color: #FAF9F8;
    flex-direction: row;

    @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
      grid-row: 3 / 4;
      grid-column: 1 / 2;
    }

    @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
      flex-direction: column;
    }

    .info {
      width: 31.4285%;

      @media only screen and (max-width: ${Breakpoints.Width.laptop_medium}) {
        width: 35%;
      }

      @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
        width: 40%;
      }

      @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
        width: 45%; 
      }

      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        width: 100%; 
      }

      h4 {
        &::after {
          background-color: ${Colors.Underline.pink};
        }
      }
    }

    .image {
      flex-grow: 1;
      background-image: url(${IdeationPrototype});
      background-size: cover;
      margin: 0;
    }
  }
  .lofi-wireframe {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
    background-color: #252B30;
    background-image: url(${DarkGrid});
    flex-direction: column;

    @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
      grid-row: 4 / 5;
      grid-column: 1 / 2;
      flex-direction: row;
    }

    @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
      flex-direction: column;
    }

    .info {
      @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
        width: 45%; 
      }

      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        width: 100%; 
      }

      h4 {
        color: ${Colors.Basic.white};
        &::after {
          background-color: ${Colors.Underline.purple};
        }
      }
      P {
        color: ${Colors.Basic.white};
      }
    }

    .image {
      flex-grow: 1;
      background-image: url(${IdeationLofimockup});
      background-size: contain;
      margin: 0 24px 24px;

      @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
        margin: 0 20px 0 0;
      }

      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        margin: 0 20px 8px 20px;
      }
    }
  }
  .task-flow {
    grid-row: 3 / 4;
    grid-column: 2 / 3;
    background-color: #3B4045;
    flex-direction: column;
    background-image: url(${IdeationTaskflow});
    background-repeat: no-repeat;
    background-size: 85% auto;
    background-position: 100% 23%;

    @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
      grid-row: 5 / 6;
      grid-column: 1 / 2;
      background-size: 70% auto;
      background-position: 100% -10%;
    }

    @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
      background-size: 85% auto;
      background-position: 50% 130px; 
    }

    .info {
      max-width: 220px;
      
      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        max-width: 100%; 
      }

      h4 {
        color: ${Colors.Basic.white};
        &::after {
          background-color: ${Colors.Underline.teal};
        }
      }
      P {
        color: ${Colors.Basic.white};
      }
    }
  }
`;

const BrandingContainer = styled.div`
  display: grid;
  grid-template-columns: 70fr 43fr 84fr;
  grid-gap: 10%;
  justify-self: center;

  @media only screen and (max-width: ${Breakpoints.Width.laptop_medium}) {
    grid-gap: 7%;
  }

  @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
    grid-gap: 5.5%;
  }

  @media only screen and (max-width: ${Breakpoints.Width.koopio_ideation}) {
    grid-gap: 4%;
  }

  @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
    grid-template-columns: 1fr;
    grid-gap: 80px;
  }

  h4 {
    font-family: ${Fonts.Font_family.title_condensed};
    font-size: 1.375rem;
    line-height: 24px;
    margin-bottom: 36px;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 12px;
      height: 1px;
      position: absolute;
      bottom: -8px;
    }
  }

  .branding-logos {
    h4 {
      &::after {
        background-color: ${Colors.Underline.blue};
      }
    }
  }
  .branding-application {
    h4 {
      &::after {
        background-color: ${Colors.Underline.pink};
      }
    }
  }
  .branding-website {
    h4 {
      &::after {
        background-color: ${Colors.Underline.green};
      }
    }
  }
`;


const Koopio = () => {
  const data = useStaticQuery(getProject);
  const project = data.project.edges[0].node;
  const sections = [
    {
      "name": "Opportunities",
      "to": "opportunities"
    },
    {
      "name": "Goals",
      "to": "goals"
    },
    {
      "name": "Ideation",
      "to": "ideation"
    },
    {
      "name": "User Interface",
      "to": "user-interface"
    },
    {
      "name": "Branding",
      "to": "branding"
    }
  ]

  return (  
    <ProjectLayout projectTitle={project.title} sections={sections}>
      <SEO title="Project - Koopio" />
      <Banner
        bgColor="#E1EFF2"
        bgImage={BannerImage}
        title={project.title}
        description={project.description.description}
        year={project.year}
        role={project.role}
      />

      <ProjectSection paddingBottom="0" name={sections[0].to}>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle>{sections[0].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle>
              Too many coupon services scatterd in different places
            </ProjectSectionsubtitle>
          </div>
          <div className="section-content__description section-content__description--list">
            <ProjectSectionDescription>
              It’s pretty hard to find coveted deals and sales from over 100 daily deal sites, including Groupon, LivingSocial, Google Offers, Amazon Local, and more. 
            </ProjectSectionDescription>
            <ProjectSectionList>
              <ProjectSectionListItem>Too many apps to explore deals.</ProjectSectionListItem>
              <ProjectSectionListItem>Hard to compare deals between diffrent services.</ProjectSectionListItem>
              <ProjectSectionListItem>Not easy to share due to services scattered.</ProjectSectionListItem>
            </ProjectSectionList>
          </div>
        </div>
      </ProjectSection>
      <OpportunitiesImage data-sal="fade" data-sal-delay="800" data-sal-duration="800"></OpportunitiesImage>

      <ProjectSection bgColor={Colors.Basic.gray_98} name={sections[1].to} >
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle>{sections[1].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle>
              Center hub for a sea of coupons services
            </ProjectSectionsubtitle>
          </div>
          <div className="section-content__description">
            <ProjectSectionDescription>
              Koopio brings together the most coveted deals and sales from over 100 daily deal sites including Groupon, LivingSocial, Google Offers, Amazon Local, and more.  No need to download multiple daily deal apps. 
            </ProjectSectionDescription>
          </div>
        </div>
        <GoalList>
          <li className="goals-image goals-image-allinone" data-sal="slide-up" data-sal-delay="300" data-sal-duration="1200">
            <h4>All in One</h4>
            <p>All the deals that you love, all in one app. Brings together the most coveted deals and sales.</p>
          </li>
          <li className=" goals-image goals-image-explore" data-sal="slide-up" data-sal-delay="450" data-sal-duration="1200">
            <h4>Explore</h4>
            <p>Browse and discover popular deals near you, organized in to easy-to-understand categories</p>
          </li>
          <li className=" goals-image goals-image-share" data-sal="slide-up" data-sal-delay="600" data-sal-duration="1200">
            <h4>Share</h4>
            <p>Save deals to personal favorites list and share with friends, and discover deals they are buying.</p>
          </li>
        </GoalList>
      </ProjectSection>

      <ProjectSection name={sections[2].to} noDescription>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle>{sections[2].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle>
              Explore the problems and solutions
            </ProjectSectionsubtitle>
          </div>
        </div>
        <IdeationContainer>
          <div className="persona" data-sal="slide-up" data-sal-delay="400" data-sal-duration="800">
            <div className="info">
              <h4>Persona</h4>
              <p>After interviewing several users, we analyzed the user and marketing research and categorized into three types of persona for the target group.</p>
            </div>
            <div className="image"></div>
          </div>
          <div className="wireframe" data-sal="slide-up" data-sal-delay="600" data-sal-duration="1200">
            <div className="info">
              <h4>Wireframe</h4>
              <p>Hand drawn sketch to quickly explore the possible ideas.</p>
            </div>
            <div className="image"></div>
          </div>
          <div className="prototype" data-sal="slide-up" data-sal-delay="600" data-sal-duration="1200">
            <div className="info">
              <h4>Prototype</h4>
              <p>Simple interactive prototype made by the wireframes to do early tests.</p>
            </div>
            <div className="image"></div>
          </div>
          <div className="lofi-wireframe" data-sal="slide-up" data-sal-delay="600" data-sal-duration="800">
            <div className="info">
              <h4>Lofi Mockup</h4>
              <p>Settle basic UI layouts after user testing with prototypes.</p>
            </div>
            <div className="image"></div>
          </div>
          <div className="task-flow" data-sal="slide-up" data-sal-delay="700" data-sal-duration="800">
            <div className="info">
              <h4>Task Flow</h4>
              <p>Plan the interaction flow of all tasks and functions.</p>
            </div>
          </div>
        </IdeationContainer>
      </ProjectSection>

      <ProjectSection bgColor="#EBEBEB" noDescription name={sections[3].to}>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle>{sections[3].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle>
              Implement the concepts with clean interface
            </ProjectSectionsubtitle>
          </div>
        </div>
        <ScreenshotsContainer infoWidth="20%" titleOffsetY="32px">
          <div className="info ui-all_in_one">
            <h4>All in one / Categorization</h4>
            <p>Collect deals from services and organize them into categories, which are easily navigated.</p>
          </div>
          <UIAllInOne></UIAllInOne>
        </ScreenshotsContainer>
        <ScreenshotsContainer infoWidth="20%" titleOffsetY="32px" underlineColor={Colors.Underline.pink}>
          <div className="info ui-explore">
            <h4>Explore</h4>
            <p>Set up filters of the locations and types to explore nearby deals.</p>
          </div>
          <UIExplore></UIExplore>
        </ScreenshotsContainer>
        <ScreenshotsContainer infoWidth="20%" titleOffsetY="32px" underlineColor={Colors.Underline.green}>
          <div className="info ui-activities">
            <h4>Activities / Share</h4>
            <p>Bookmark the favorite deals, browser people’s activities, and share them with friends.</p>
          </div>
          <UIActivities></UIActivities>
        </ScreenshotsContainer>
      </ProjectSection>

      <ProjectSection bgColor="#FBFBFB" name={sections[4].to}>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle>{sections[4].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle>
              Build up the identity to express the value
            </ProjectSectionsubtitle>
          </div>
          <div className="section-content__description">
            <ProjectSectionDescription>
              Build the identity system from scratch, including logo, website, and advertisement materials. 
            </ProjectSectionDescription>
          </div>
        </div>
        <BrandingContainer>
          <div className="branding-logos" data-sal="slide-up" data-sal-delay="400" data-sal-duration="800">
            <h4>Logos</h4>
            <BrandingLogos></BrandingLogos>
          </div>
          <div className="branding-application" data-sal="slide-up" data-sal-delay="500" data-sal-duration="800">
            <h4>Application</h4>
            <BrandingApplication></BrandingApplication>
          </div>
          <div className="branding-website" data-sal="slide-up" data-sal-delay="600" data-sal-duration="800">
            <h4>Website</h4>
            <BrandingWebsite></BrandingWebsite>
          </div>
        </BrandingContainer>
      </ProjectSection>

      <ProjectFooter currentProjectOrder="1">
      </ProjectFooter>
    </ProjectLayout>
  )
};

export default Koopio;
