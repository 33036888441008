import React from "react";
import styled from "styled-components";

import Colors from "../../constants/colors";
import Fonts from "../../constants/fonts";
import Breakpoints from "../../constants/breakpoints";

const Container = styled.div`
  display: flex;
  flex-direction: ${props => props.column ? 'column' : 'row'};

  @media only screen and (max-width: ${Breakpoints.Width.project_medium}) {
    flex-direction: column;
  }

  &&:not(:last-child) {
    margin-top: ${props => props.marginTop || '0'};
    margin-bottom: ${props => props.marginBottom || '100px'};

    @media only screen and (max-width: ${Breakpoints.Width.project_medium}) {
      margin-bottom: 80px;
    }
  }

  .info {
    width: ${props => props.infoWidth || '15%'};
    margin-right: 5%;

    @media only screen and (max-width: ${Breakpoints.Width.project_medium}) {
      width: 100%;
      margin-right: 0;
      padding-bottom: 8px;
    }

    h4{
      font-family: ${Fonts.Font_family.title_condensed};
      font-size: 1.375rem;
      line-height: 24px;
      font-weight: ${props => props.titleFontWeight || '700'};
      color: ${props => props.titleColor || Colors.Text.default};
      margin-top: ${props => props.titleOffsetY || '0'};
      position: relative;

      @media only screen and (max-width: ${Breakpoints.Width.project_medium}) {
        margin-top: 0;
      }

      &::after {
        content: "";
        display: block;
        width: 12px;
        height: 1px;
        position: absolute;
        bottom: -8px;
        background-color: ${props => props.underlineColor || Colors.Underline.blue};
      }
    }

    p {
      font-size: 0.9375rem;
      color: ${props => props.titleColor || Colors.Text.default};
      margin-bottom: ${props => props.column ? '32px' : '0'};

      @media only screen and (max-width: ${Breakpoints.Width.project_medium}) {
        margin-bottom: 24px;
      }
    }
  }
`;

const ScreenshotsContainer = props => {
  return (
    <Container data-sal="slide-up" data-sal-delay="600" data-sal-duration="800"
      column={props.column}
      infoWidth={props.infoWidth}
      titleColor={props.titleColor}
      titleFontSize={props.titleFontSize}
      titleFontWeight={props.titleFontWeight}
      titleOffsetY= {props.titleOffsetY}
      underlineColor={props.underlineColor}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
    >
      {props.children}
    </Container>
  );
};


export default ScreenshotsContainer;