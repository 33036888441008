import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from 'styled-components';

import Colors from '../../constants/colors';
import Breakpoints from "../../constants/breakpoints";

const getData = graphql`
  query {
    allInOne: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/Koopio/UI_screens/All_in_one" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    explore: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/Koopio/UI_screens/Explore" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    activities: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/Koopio/UI_screens/Activities" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    brandingLogos: file(relativePath: { eq: "images/projects/Koopio/branding/logos.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    brandingApplication: file(relativePath: { eq: "images/projects/Koopio/branding/application.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    brandingWebsite: file(relativePath: { eq: "images/projects/Koopio/branding/website.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

function withImageData(WrappedComponent) {
  return props => (
    <StaticQuery
      query={getData}
      render={data => <WrappedComponent {...props} imageData={data} />}
    />
  );
}

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 75%;

  @media only screen and (max-width: ${Breakpoints.Width.project_medium}) {
    width: 100%;
  }

  @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
    flex-direction: column;
  }

  > div {
    width: 29%;
    max-width: 240px;

    @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
      width: 31%;
    }
  
    @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
      width: 100%;
      max-width: 100%;
    }

    &:not(:last-child) {
      margin-right: 6.5%;

      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        margin-right: 3.5%;
      }

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }

    img {
      border: 1px solid ${Colors.Basic.gray_88};
    }
  }

`;

export const UIAllInOne = withImageData(props => (
  <ImagesContainer>
    {props.imageData.allInOne.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
        />
    ))}
  </ImagesContainer>
));

export const UIExplore = withImageData(props => (
  <ImagesContainer>
    {props.imageData.explore.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
        />
    ))}
  </ImagesContainer>
));


export const UIActivities = withImageData(props => (
  <ImagesContainer>
    {props.imageData.activities.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
        />
    ))}
  </ImagesContainer>
));


export const BrandingLogos = withImageData(props => (
  <Img fluid={props.imageData.brandingLogos.childImageSharp.fluid} alt="Logo concept" />
));

export const BrandingApplication = withImageData(props => (
  <Img fluid={props.imageData.brandingApplication.childImageSharp.fluid} alt="App icon and splash screen" />
));

export const BrandingWebsite = withImageData(props => (
  <Img fluid={props.imageData.brandingWebsite.childImageSharp.fluid} alt="Koopio website" />
));